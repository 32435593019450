<template>
    <div>
        <div class="header">
            <span class="header_txt">抽奖设置</span>
        </div>
        <div class="content">
            <Form ref="drawListForm" :model="drawListForm" :rules="drawListFormRules" :label-width="120">
                <FormItem label="抽奖名称" prop="name">
                    <Input type="text" v-model="drawListForm.name" style="width: 200px;" placeholder="请输入抽奖名称"></Input>
                </FormItem>
                <FormItem label="中奖名额" prop="winning_num">
                    <InputNumber :max="5" :min="1" v-model="drawListForm.winning_num" controls-outside></InputNumber>
                </FormItem>
                <FormItem label="额外增加50人" prop="additional">
                    <template>
                        <i-switch v-model="drawListForm.additional" :true-value="1" :false-value="0"></i-switch>
                    </template>
                </FormItem>
                <FormItem label="直播在线" prop="live_online">
                    <template>
                        <i-switch v-model="drawListForm.live_online" :true-value="1" :false-value="0"></i-switch>
                    </template>
                </FormItem>
                <FormItem label="直播下单" prop="live_ordering">
                    <template>
                        <i-switch v-model="drawListForm.live_ordering" :true-value="1" :false-value="0"></i-switch>
                    </template>
                </FormItem>
                <FormItem label="指定中奖人" prop="designated_winning">
                    <template>
                        <i-switch v-model="drawListForm.designated_winning" :true-value="1" :false-value="0"></i-switch>
                    </template>
                </FormItem>
                <FormItem v-if="drawListForm.designated_winning == 1" label="添加指定中奖人">
                    <Button type="primary" @click="add">添加</Button>
                </FormItem>
                <!-- 表格 -->
                <Table v-if="drawListForm.designated_winning == 1" :columns="modalTable" :data="modalDataTable" border>
                    <template slot-scope="{row}" slot="shop">
                        <span>{{row.shop.name}}</span>
                    </template>
                    <template slot-scope="{row}" slot="portrait">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                            <img v-if="row.portrait" :src="row.portrait.path" alt="" style="width: 50px;">
                            <div v-else style="width: 56px;height: 56px;background-color: #f5f7fa;color:#c0c4cc;line-height:56px;text-align:center;">加载失败</div>
                        </div>
                    </template>
                    <template slot-scope="{row,index}" slot="type">
                        <!-- <Button type="primary" @click="addModals(row)">添加</Button> -->
                        <Button type="error" @click="delModals(row,index)">移除</Button>
                    </template>
                </Table>
            </Form>
            <div class="footer">
                <Button @click="jumpBack" style="width: 200px;height: 40px;margin-right: 10px;">取消</Button>
                <Button type="primary" @click="save('drawListForm')" style="width: 200px;height: 40px;">保存</Button>
            </div>
        </div>
        <!-- 添加指定中奖人 -->
        <Modal v-model="addModal" title="添加指定中奖人" width="50%">
            <div class="addModal_content">
                <div style="display:flex;flex-direction:row;width: 50%;margin-bottom: 20px;">
                    <Input v-model="storePramsModal.keywords" type="text" placeholder="客户名字/手机号" />
                    <Button type="primary" @click="search">搜索</Button>
                </div>
                <Table :columns="detailsTable" :data="detailsDataTable" border>
                    <template slot-scope="{row}" slot="shop">
                        <span>{{row.shop.name}}</span>
                    </template>
                    <template slot-scope="{row}" slot="portrait">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                            <img v-if="row.portrait" :src="row.portrait.path" alt="" style="width: 50px;">
                            <div v-else style="width: 56px;height: 56px;background-color: #f5f7fa;color:#c0c4cc;line-height:56px;text-align:center;">加载失败</div>
                        </div>
                    </template>
                    <template slot-scope="{row}" slot="type">
                        <Button type="primary" @click="addModals(row)">添加</Button>
                        <!-- <Button type="error" @click="delModals">移除</Button> -->
                    </template>
                </Table>
            </div>
            <div class="footer_modal">
                <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
            </div>
            <div slot="footer">
                <Button @click="addModalF">取消</Button>
                <Button type="primary" @click="addModalT">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {liveAddDraw, drawInfo, customerList} from '@/api/index'
    export default {
        data() {
            return {
                totalModal: 3,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    keywords:'',
                    phone: '',
                    shop_id: -1
                },
                addModal: false,
                modalTable: [
                    {title: '姓名',key: 'nickname',align:'center'},
                    {title: '店铺',slot: 'shop',align:'center'},
                    {title: '手机号',key: 'phone',align:'center'},
                    {title: '头像',slot: 'portrait',align:'center'},
                    {title: '操作',slot: 'type',align:'center'}
                ],
                modalDataTable: [],
                detailsTable: [
                    {title: '姓名',key: 'nickname',align:'center'},
                    {title: '店铺',slot: 'shop',align:'center'},
                    {title: '手机号',key: 'phone',align:'center'},
                    {title: '头像',slot: 'portrait',align:'center'},
                    {title: '操作',slot: 'type',align:'center'}
                ],
                detailsDataTable: [],
                drawListForm:{
                    name:'',
                    winning_num: 0,
                    additional: 0,
                    live_online: 0,
                    live_ordering: 0,
                    designated_winning: 0,
                    winning_text: '',
                    live_id: '',
                    id: ''
                },
                // 抽奖设置-表单验证
                drawListFormRules: {
                    name: [
                        { required: true, message: '请输入抽奖名称', trigger: 'blur' }
                    ],
                    number: [
                        { required: true, message: '', trigger: 'blur' }
                    ],
                },
            }
        },
        created(e){      
            let id = this.$route.query.id ? this.$route.query.id : ''
            if(id){
                //修改
                this.drawListForm.id = id
                this.drawInfo()
            }
            else {
                //新增
                this.drawListForm.name = ''
                this.drawListForm.winning_num = 0
                this.drawListForm.additional = 0
                this.drawListForm.live_online = 0
                this.drawListForm.live_ordering = 0
                this.drawListForm.designated_winning = 0
                this.drawListForm.winning_text = ''
                this.drawListForm.live_id = this.$route.query.liveid
                this.drawListForm.id = ''
            }            
        },
        methods: {
            addModals(item){
                console.log('item',item)
                this.modalDataTable.push(item)
            },
            delModals(item,i){
                console.log('item',item)
                this.modalDataTable.splice(i, 1)
            },
            // search
            search(){
                this.customerList()
            },
            drawInfo(){
                drawInfo({id:this.drawListForm.id}).then(res => {
                    console.log('res',res.data)
                    this.drawListForm = res.data
                    this.modalDataTable = this.drawListForm.winning_text
                })
                .catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            pageChangeModal(index){
                this.storePramsModal.page = index;
                this.customerList()
            },
            // 员工列表
            customerList(){
                customerList(this.storePramsModal).then(res => {
                    console.log('员工列表', res.data.data)
                    this.totalModal = res.data.total
                    this.detailsDataTable = res.data.data
                })
            },
            // 添加
            add(){
                this.addModal = true
                this.customerList()
            },
            addModalF(){
                this.addModal = false
            },
            addModalT(){
                this.addModal = false
            },
            jumpBack(){
                this.$router.back()    
            },
            save(name){
                var data = {
                    name: this.drawListForm.name,
                    winning_num: this.drawListForm.winning_num,
                    additional: this.drawListForm.additional,
                    live_online: this.drawListForm.live_online,
                    live_ordering: this.drawListForm.live_ordering,
                    designated_winning: this.drawListForm.designated_winning,
                    winning_text: this.modalDataTable,
                    live_id: this.drawListForm.live_id,
                    id: this.drawListForm.id,
                }
                if(this.drawListForm.id){
                    liveAddDraw(data).then(res => {
                        this.$router.back()
                        this.$Message.success(res.msg);
                    })
                    .catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }else{
                    data.id = ''
                    liveAddDraw(data).then(res => {
                        this.$router.back()
                        this.$Message.success(res.msg);
                    })
                    .catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.content{
    margin: 20px 0;
}
::v-deep .ivu-input-number-input{
    text-align: center;
}
.footer{
    margin-top: 20px;
}
.footer_modal{
    margin-top: 20px;
    text-align: right;
}
</style>